const blogArticles = [
  {
    title: "News",
    articles: [
      {
        id: 1,
        title: "New DevOps as a Service Solution Accelerates Digital Business",
        titleInside: "New DevOps as a Service Solution by Lithuanian IT company HyperOps Accelerates Digital Business",
        date: "January 27, 2021",
        readTime: 4,
        image: "news-article1.jpg",
        text: `
        HyperOps, a Lithuanian IT company providing maintenance and infrastructure solutions for critical IT systems, has launched a multifunctional DevOps as a Service solution. The service is provided through a fully integrated platform and is aimed at organizations where the core business is dependent on digital systems, including financial service providers, e-commerce, data processing companies and mobile app developers. The DevOps as a Service solution will save valuable IT staff time, reduce human errors, and help implement updates without downtime.

        <h6>DevOps – not only a set of tools</h6>
        “The term DevOps – Development & Operations – is not new in the Baltics. We have noticed a growing interest in DevOps competencies and a need for the value they bring. That is why we assessed the potential and developed the product targetting that space. DevOps is more than a combination of tools and services. It is a cultural movement within IT, and a methodology that assists developers, system administrators and product development teams and helps them work together more efficiently. It also helps serve e-products to the market and get upgrades online more rapidly, as well as reduce human error and service disruption risks. The simple comparison of DevOps usability could be similar to paying bills by regular bank transfer versus a payment through the e-banking app with one click,” said Gediminas Grigas, CEO of HyperOps, who has introduced DevOps philosophy in the company and put it into practice.

        <h6>A Unique Fully Operational Platform</h6>
        DevOps as a Service will help businesses implement DevOps values ​using one single platform with full access to the tools, environments, and containerized infrastructure they need. Technical solutions and processes relevant to the customer’s business are pre-integrated into the platform and ready for instant use while the consulting DevOps experts support the initial steps. The multifunctional portfolio of services is delivered through a fully managed online platform developed by the HyperOps DevOps team.

        “Our DevOps as a Service platform will help you experience the key benefits of DevOps very quickly. The processes for programming, testing, launch and upgrades will be much faster thanks to the containerized infrastructure. The service facilitates the entire chain of implementation processes – from the programmer to the end-user,” said Kęstutis Jatužys, DevOps Team Lead at HyperOps.

        HyperOps DevOps as a Service is currently launching in the Baltic market, with future expansion to other European countries. The DevOps as a Service DEMO version is available right now and can be tested by contacting HyperOps at devops@hyperops.net or filling out the online form at devops.hyperops.net.

        ***

        <h6>About HyperOps</h6>
HyperOps has been serving the most demanding financial, telecommunications, media and e-commerce companies in Europe for over 17 years. The company provides fully managed IT infrastructure solutions and DevOps services, specializing in maintaining critical systems 24/7/365. Since 2004, HyperOps retains its status as a trusted IT partner and has a proven track record of providing one of the fastest response times according to the Service Level Agreements within the IT industry in the Baltics.  
        `,
      },
    ],
  },
];

// const blogArticles1 = [
//   {
//     title: "Cybersecurity",
//     articles: [
//       {
//         id: 1,
//         url: "article-cryptocurency",
//         title: "Cryptocurrency 101: Is it really safe?",
//         date: "January 14, 2021",
//         readTime: 4,
//         text:
//           "The market is bursting with new ideas – everyone’s trying to create something unique and earn the world’s attention. Every well-known company started with a regular person and an idea: Facebook was launched in Harvard’s dorm rooms, three guys renting mattresses created Airbnb, Linkedin came into existence from someone’s living room, and so on. Tesonet has a similar story with two main characters – Tomas and Eimantas in Fabijoniškės. However, over time Tesonet changed from a startup into an accelerator, turning products built in-house into independent businesses worldwide. In this week’s blog, we talked with one of our CTO’s here at Tesonet, Kazimieras Celiešius, about the differences and perks of startups and accelerators."
//       },
//       {
//         id: 2,
//         url: "article-hacked",
//         title: "How to know if you’ve been hacked",
//         date: "January 14, 2021",
//         readTime: 4,
//         text:
//           "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum maiores ea at nulla pariatur suscipit cum voluptas. Ea porro eos quae qui, sunt nisi reprehenderit exercitationem in, aliquid animi laborum!"
//       },
//       {
//         id: 3,
//         url: "article-cryptocurency-mining",
//         title: "Cryptocurrency mining?",
//         date: "January 14, 2021",
//         readTime: 4,
//         text:
//           "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum maiores ea at nulla pariatur suscipit cum voluptas. Ea porro eos quae qui, sunt nisi reprehenderit exercitationem in, aliquid animi laborum!"
//       },
//       {
//         id: 4,
//         url: "article-cryptocurency-money",
//         title: "Cryptocurrency 101: Is it really safe?",
//         date: "January 14, 2021",
//         readTime: 4,
//         text:
//           "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum maiores ea at nulla pariatur suscipit cum voluptas. Ea porro eos quae qui, sunt nisi reprehenderit exercitationem in, aliquid animi laborum!"
//       }
//     ]
//   },
//   {
//     title: "Tech",
//     articles: [
//       {
//         id: 5,
//         url: "article-books",
//         title: "Top 10 books to feed your techy mind",
//         date: "January 14, 2021",
//         readTime: 4,
//         text:
//           "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum maiores ea at nulla pariatur suscipit cum voluptas. Ea porro eos quae qui, sunt nisi reprehenderit exercitationem in, aliquid animi laborum!"
//       },
//       {
//         id: 6,
//         url: "article-events",
//         title: "10 online events for tech thirsty minds",
//         date: "January 14, 2021",
//         readTime: 4,
//         text:
//           "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Rerum maiores ea at nulla pariatur suscipit cum voluptas. Ea porro eos quae qui, sunt nisi reprehenderit exercitationem in, aliquid animi laborum!"
//       }
//     ]
//   }
// ];

export default blogArticles;
